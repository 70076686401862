<template>
  <div class="field">
    <label class="label">{{label}}</label>
    <div class="control">
        <textarea 
          :class="inputClass()" 
          :placeholder="placeholder"
          :disabled="disabled"
          @input="$emit('input', $event.target.value)"
          :value="value"
        >
        </textarea>
    </div>
    <p 
      v-if="error"
      v-html="formatErrorMsg()"
      class="help is-danger"
    >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    },
    validation: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    error: function() {
      if (this.validation && this.validation.$error) {
        return this.validation.$error
      } else {
        return false
      }
    }
  },
  methods: {
    formatErrorMsg() {
      if (this.error) {
        return "input not valid"
      } else {
        return "" 
      }
    },
    inputClass() {
      if (!this.error) {
        return "textarea"
      } else {
        return "textarea is-danger"
      }
    }
  }
};
</script>


