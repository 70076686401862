<template>
   <SettingsPage
     v-if="canEdit"
     type="business"
   >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <form @submit.prevent="onSubmit">
       <BaseInput
        label="Business name"
        v-model.trim="$v.business.name.$model"
        :validation="$v.business.name"
        @input="detectChange()"
      />

      <div style="padding-bottom: 10px;">
        <label class="label">Locations</label>
        <ul>
          <li
            v-for="l in business.locations"
            :key="l.formattedAddress"
            style="padding:5px 0;"
          >
            <span class="tag is-medium">
              {{ l.formattedAddress }}
              <button
                @click="deleteLocation(l.formattedAddress)"
                class="delete is-small"></button>
            </span>
          </li>
        </ul>
        <a
          v-if="!addressFormShow"
          @click="addressFormShow = !addressFormShow"
        >
          Add Location
        </a>
        <BaseAddressSearch
          v-if="addressFormShow"
          v-model="newAddress"
          :business="business.name"
        />
      </div>


      <div class="field">
        <label class="label">Website</label>
        <div>
          <input
            type="text"
            class="input"
            v-model.trim="business.website"
            @input="detectChange()"
          >
        </div>
      </div>

      <BaseInput
        label="Phone"
        v-model.trim="$v.business.phone.$model"
        :validation="$v.business.phone"
        :mask="'(###) ###-####'"
        @input="detectChange()"
      />
      <BaseTextarea
        label="About"
        v-model.trim="$v.business.about.$model"
        :validation="$v.business.about"
        @input="detectChange()"
      />
      <div class="field">
        <label class="label">Award method</label>
        <div class="control">
          <div class="select">
            <select
              v-model="business.award_method"
              @change="detectChange()"
            >
              <option
                v-for="opt in award_method_options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label || 'No label' }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Default Image</label>
        <div class="control">
          <img :src="business.image" :title="business.name" />
          <BaseUpload
            label="Change image"
          />
        </div>
      </div>
      <BaseSubmit
        label="Save"
        :disabled="$v.$invalid || noChanges"
        :submitting="submitting"
        :clearShow="true"
      />
    </form>
  </SettingsPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import { required } from 'vuelidate/lib/validators';
import SettingsPage from "@/components/SettingsPage.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import BaseAddressSearch from "@/components/form/BaseAddressSearch.vue";

export default {
  components: {
    SettingsPage,
    Notification,
    BaseInput,
    BaseTextarea,
    BaseAddressSearch,
    BaseUpload,
    BaseSubmit
  },
   data() {
    return {
      loading: false,
      noChanges: true,
      error: null,
      submitting: false,
      addressFormShow: false,
      newAddress: '',
      business: {
        name: '',
        about: '',
        website: '',
        phone: '',
        locations: [],
        award_method: 'both'
      },
      award_method_options: [
        {
          label: "One-click",
          value: "click",
        },
        {
          label: "Scan QR",
          value: "scan",
        },
        {
          label: "Both",
          value: "both",
        },
      ]
    }
  },
  validations: {
    business: {
      name: { required },
      about: { required },
      phone: { required }
    }
  },
  computed: {
    canEdit: function() {
      return this.$store.getters.canEdit;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {
      this.business = this.currentBusiness
    } else {
      this.$router.push("/o/organization-new")
    }
  },
  methods: {
    detectChange() {
      this.noChanges = false
    },
    reset() {
      this.noChanges = true
      this.$router.go()
    },
    uploadImg(image_url) {
      this.business.image = image_url
    },
    addLocation(location) {
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          new_location: location
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.addressFormShow = false
          this.newAddress = ''
          this.business.locations = this.currentBusiness.locations
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    },
    deleteLocation(formattedAddress) {
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          delete_location: {
            formatted_address: formattedAddress
          }
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.business.locations = this.currentBusiness.locations
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    },
    onSubmit() {
      this.submitting = true;
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: this.business
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.submitting = false
          this.noChanges = true
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>
